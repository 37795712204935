import { useNavigate } from "react-router-dom";
import LogoClosepay from "../assets/images/closepayLogo.svg";
import { useAppSelector } from "../core/feature/hooks";
import { useTranslation } from "react-i18next";

export default function Navbar() {
  const {t}= useTranslation()
  const company: any = useAppSelector((state) => state.company.company);
  let navigate = useNavigate();
  let logo;
  const colour = {
    colourPrimary: `${company ? company.config?.colourPrimary : "#0AB185"}`,
    colourSecondary: `${company ? company.config?.colourSecondary : "#00d19f"}`,
  };

  if (company) {
    if (company?.logo == "string" || null || undefined) {
      logo = LogoClosepay;
    } else {
      logo = company.logo;
    }
  }

  return (
    <div
      className="flex items-center top-0 right-0 left-0 justify-between m-0 dark:bg-slate-900 dark:text-white py-2 px-6 z-50 w-full"
      style={{ backgroundColor: `#26A69A` }}
    >
      <div className="hidden md:flex 2xl:flex md:w-3/4 2xl:2/5 mx-auto">
        <img src={company?.companyLogo} className="h-14 cursor-pointer" />
      </div>
      <div className="md:hidden font-bold text-lg mt-4 mx-auto">
        {t("invoice.titleNav")}
      </div>
    </div>
  );
}
