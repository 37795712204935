import React, { useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";

export default function ChooseLanguage() {
  const { i18n } = useTranslation();
  const language = [
    { label: "Bahasa Indonesia", value: "id" },
    { label: "Bahasa Inggris", value: "en" },
  ];
  const check = localStorage.getItem("i18nextLng");
  const [choose, setChoosen] = useState(
    language.find((el: any) => check === el.value)
  );
  const [show, setShow] = useState(false);

  const change = (e: any) => {
    i18n.changeLanguage(e.value);
    setChoosen(e);
    return;
  };
  return (
    <div className="p-2 flex flex-col justify-end items-end text-xs md:text-sm">
      <div className=" cursor-pointer" onClick={() => setShow(!show)}>
        <FontAwesomeIcon icon={faLanguage} className="mr-1" />
        Ubah Bahasa
      </div>
      {show && (
        <Select options={language} onChange={(e) => change(e)} value={choose} />
      )}
    </div>
  );
}
