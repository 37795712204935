import cicil from "../assets/images/icon_invoicelink/cicil.svg";
import unpaid from "../assets/images/icon_invoicelink/belumbayar.svg";
import paid from "../assets/images/icon_invoicelink/lunas.svg";


import moment from "moment";
var idLocale = require("moment/locale/id");
const momentLocal = moment.locale("id", idLocale);

export const createArrayEntityFromObject = (obj) => {
  return Object.keys(obj);
};

export const getEntity = (keys, obj) => {
  return keys
    .map((k) => {
      if (typeof obj[k] == "boolean") {
        // console.log("obj[k]", obj[k], "[k]", [k]);
        return obj[k];
        // return { key: [k], value: obj[k] };
      } else {
        if (obj[k]) return { key: [k], value: obj[k] };
      }
    })
    .filter((el) => el != undefined);
};

export const getEntityPy = (keys, obj) => {
  return keys
    .map((k) => {
      if (typeof obj[k] == "boolean" || typeof obj[k] == "number") {
        // console.log("obj[k]", obj[k], "[k]", [k]);
        // return obj[k];
        return { key: [k], value: obj[k] };
      } else {
        if (obj[k]) return { key: [k], value: obj[k] };
      }
    })
    .filter((el) => el != undefined);
};

export const createQueryUrl = (arr, separator) => {
  let query = "";
  arr.map((el, n) => {
    if (arr.length - 1 > n)
      return (query = `${query}${el.key}=${el.value}${separator}`);
    if (typeof el.value == "boolean" || typeof el.value == "number") {
      // console.log("el", el);
      query = `${query}${el.key}=${el.value}`;
    } else if (el.value) {
      query = `${query}${el.key}=${el.value}`;
    }
  });
  // console.log(query);
  return query;
};

export const findArray = (arr, fromKey, value, returnKey) => {
  if (arr.find((el) => el[fromKey].includes(value))) {
    return arr.find((el) => el[fromKey].includes(value))[returnKey];
  } else {
    return null;
  }
};

export const formatCurrency = (value) => {
  if (value == "" || value == null || value == undefined) return "Rp 0";
  return new Intl.NumberFormat("in-IN", {
    style: "currency",
    currency: "IDR",
  }).format(value);
};

export const onlyNumber = (evt) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46)
    return evt.preventDefault();
  return true;
};

export const isNumeric = (number) => {
  if (+number === +number) {
    // if is a number
    return true;
  }

  return false;
};

export const backTo = (url, query = {}) => {
  window.location.replace(url);
};

export const checkType = (data) => {
  if (data) {
    if (parseInt(data) != NaN) {
      return parseInt(data);
    }
    return data;
  }
};

export const createNewArray = (array, keys, nested = {}) => {
  let returnArr = [];
  array.map((arr) => {
    let obj = {};
    keys.map((key) => {
      obj[key.to] = arr[key.from];
    });
    if (nested && arr[nested.from]) {
      obj[nested.to] = createNewArray(arr[nested.from], keys, nested);
    }
    returnArr.push(obj);
  });
  return returnArr;
};


export const toMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return moment(date).format("MMMM");
};

export const subStringText = (message, start, end, a) => {
  if (message?.length >= a) {
    return message.substring(start, end) + "...";
  } else {
    return message;
  }
};

export const camelCase = (text) => {
  return text
    .toLowerCase()
    .split(" ")
    .map((el, ind) => {
      if (el) {
        return el[0].toUpperCase() + el.substring(1, el.length);
      }
    })
    .join(" ");
};

export const replaceText = (text, from, to) => {
  return text.replaceAll(from, to);
};

export const humanizedText = (text) => {
  return text.replaceAll("-", " ").replaceAll("_", " ");
};

export const cekToken = () => {
  if (localStorage.getItem("token_redux"))
    return JSON.parse(localStorage.getItem("token_redux").replaceAll('"', ""));
  return "";
};

export const urlFeature = (name) => {
  switch (name) {
    case "POS":
      return "/pos";
    case "MARKETPLACE":
      return "/marketplace";

    default:
      break;
  }
};
// export const nameFeature = (name) => {
//   switch (name) {
//     case 'POS':
//       return 'Pos'
//     case 'MARKETPLACE':
//       return 'Marketplace'
//     case 'TOP_UP_MERCHANT':
//       return null
//     case 'QR_CASH_OUT':
//       return null
//     case 'WITHDRAW_BALANCE':
//       return null
//     case 'SCAN_QR':
//       return null

//     default:
//       break;
//   }
// };

export const statusOrder = (item) => {
  switch (item) {
    case "CREATED":
      return "Order Masuk";
    case "PROCESSED":
      return "Diproses";
    case "CANCELED":
      return "Dibatalkan";
    case "CONFIRMED":
      return "Selesai";
    case "SENT":
      return "Dikirim";
    case "REJECTED":
      return "Ditolak";

    default:
      break;
  }
};

// export const iconFeature = (el) => {
//   switch (el) {
//     case "BALANCE":
//       return Saldo;
//     case "QRIS":
//       return Qris
//     case "TRANSFER_BANK":
//       return Bank

//     default:
//       break;
//   }
// };

export const formatDateTime = (text) => {
  return moment(text)
    .locale("id")
    .format("DD.MM.YYYY" + " " + "HH:MM");
};
export const formatDate = (text,locale) => {
  return moment(text).format("DD MMMM YYYY");
};
export const formatTimeMinute = (time) => {
  return moment(time).locale("id").format("mm:ss");
};
export const formatMonth = (text) => {
  switch (text) {
    case 1:
      return "JANUARI";
    case 2:
      return "FEBRUARI";
    case 3:
      return "MARET";
    case 4:
      return "APRIL";
    case 5:
      return "MEI";
    case 6:
      return "JUNI";
    case 7:
      return "JULI";
    case 8:
      return "AGUSTUS";
    case 9:
      return "SEPTEMBER";
    case 10:
      return "OKTOBER";
    case 11:
      return "NOVEMBER";
    case 12:
      return "DESEMBER";

    default:
      break;
  }
};

export const status = (data) => {
  switch (data) {
    case "UNPAID":
      return unpaid;
    case "PARTIAL":
      return cicil;
    case "PAID":
      return paid;
    default:
      break;
  }
};
