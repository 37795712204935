import React, { FC, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../core/feature/hooks";
import { Link, useNavigate, useParams } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";
import { onlyNumber } from "../config/global";
import { useTranslation } from "react-i18next";

interface InputProps {
  value: any;
  setValue: any;
  submit: any;
  final: any;
  allowed: any;
}
const InputComponent: FC<InputProps> = (props): JSX.Element => {
  const { t } = useTranslation();

  const methodPayment = () => {
    if (props.value) {
      return false;
    } else {
      return true;
    }
  };

  const checked = (el: any) => {
    if (props.value == el) {
      props.setValue("");
    } else if (props.value !== el) {
      props.setValue(el);
    }
  };

  useEffect(() => {
    if (props.final != props.value) {
      props.setValue("");
    }
    if (!props.allowed) {
      props.setValue(props.final);
    }
  }, [props.final]);

  return (
    <div className="w-full mx-auto pt-14">
      <div className="md:hidden slide-from-bottom fixed-bottom bg-white p-4 rounded-lg space-y-4 border border-slate-400 mt-20">
        <div>{t("invoice.totalPayment")}</div>
        <div className="">
          <CurrencyInput
            className={`${
              props.value > props.final
                ? "border-1 focus:border-red-400 focus:outline-none w-full"
                : "block border border-slate-400 w-full"
            } w-full appearance-none rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none p-2`}
            decimalsLimit={2}
            value={props.value}
            disabled={props.value == props.final || !props.allowed}
            onKeyPress={onlyNumber}
            prefix={"Rp "}
            onValueChange={(value, name) => {
              props.setValue(value || "");
            }}
          />
          <span
            className="text-red-400 text-xs"
            hidden={props.value <= props.final}
          >
            {t("invoice.over")}
          </span>
          <div className="flex justify-end">
            <input
              type="checkbox"
              value={props?.final}
              checked={props.value == props.final || !props.allowed}
              disabled={!props.allowed}
              onClick={() => checked(props?.final)}
              onChange={() => checked(props?.final)}
              className="mr-2"
            />
            <span>{t("invoice.payAll")}</span>
          </div>
        </div>
        <div>
          <button
            disabled={methodPayment()}
            className={`${
              methodPayment() ? " bg-gray-300" : "bg-[#26A69A]"
            } text-center w-full p-2 rounded-lg text-white`}
            onClick={props.submit}
          >
            {t("invoice.pay")}
          </button>
        </div>
      </div>
      <div className="hidden md:block bg-white p-4 rounded-lg space-y-2 border border-slate-400 fixed-bottom md:w-[45%] mx-auto slide-from-bottom">
        <div>{t("invoice.totalPayment")}</div>
        <div className="bg-slate-400">
          <CurrencyInput
            className={`${
              props.value > props.final
                ? "border-1 focus:border-red-400 focus:outline-none"
                : "block border border-slate-400"
            } w-full appearance-none rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none p-2`}
            decimalsLimit={2}
            value={props.value}
            disabled={props.value == props.final || !props.allowed}
            onKeyPress={onlyNumber}
            prefix={"Rp "}
            onValueChange={(value, name) => {
              props.setValue(value || "");
            }}
          />
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-2 ">
            <span
              className="text-red-400 text-sm"
              hidden={props.value <= props.final}
            >
              {t("invoice.over")}
            </span>
          </div>
          <div className="flex justify-end items-center">
            <input
              type="checkbox"
              value={props?.final}
              checked={props.value == props.final || !props.allowed}
              disabled={!props.allowed}
              onClick={() => checked(props?.final)}
              onChange={() => checked(props?.final)}
              className="mr-2"
            />
            <span>{t("invoice.payAll")}</span>
          </div>
        </div>
        <div>
          <button
            disabled={methodPayment()}
            className={`${
              methodPayment() ? " bg-gray-300" : "bg-[#26A69A]"
            } text-center w-full p-2 rounded-lg text-white`}
            onClick={props.submit}
          >
            {t("invoice.pay")}
          </button>
        </div>
      </div>
    </div>
  );
};
export default InputComponent;
